/* globals turnstile */

$(() => {
  $('.ui.form').form({
    fields: {
      username: ['empty', 'email'],
      password: 'empty',
    },
  });
});

$('#privacy-policy').on('click', (e) => {
  e.preventDefault();

  $('#modal-privacy-policy').modal('show');
});

$('#terms-policy').on('click', (e) => {
  e.preventDefault();

  $('#modal-terms-policy').modal('show');
});

$('.form').on('submit', (e) => {
  e.preventDefault();

  if (!$('.form').form('is valid')) return false;

  const username = $('input[name="username"]').val();
  const password = $('input[name="password"]').val();
  const cf = $('input[name="cf-turnstile-response"]').val();

  $('.form').addClass('loading');

  fetch(
    `${location.protocol.concat('//').concat(window.location.host)}/login`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, 'cf-turnstile-response': cf }),
      credentials: 'include',
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        // Handle successful login
        $.toast({
          position: 'top attached',
          class: 'success',
          message: 'Login successful',
        });

        $('.form').text('Loading dashboard ...');
        $('.ui.basic.message').addClass('hidden');

        window.location.replace('/');
      } else {
        // if (data?.cft_error) turnstile.reset();
        turnstile.reset();

        $.toast({
          position: 'top attached',
          class: 'error',
          message: 'Authentication failed',
        });
      }
    })
    .catch((error) => console.error(error))
    .finally(() => {
      $('.form').removeClass('loading');
    });
});

$('#reset-it').on('click', (e) => {
  e.preventDefault();

  if ($('.ui.form').form('is valid', 'username')) {
    $.toast({
      position: 'top attached',
      class: 'blue',
      message: 'Initiating recovery...',
    });

    const username = $('input[name="username"]').val();
    const cf = $('input[name="cf-turnstile-response"]').val();

    fetch(
      `${location.protocol
        .concat('//')
        .concat(window.location.host)}/login/recovery`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, 'cf-turnstile-response': cf }),
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $.modal({
            title: 'Account Recovery',
            class: 'small',
            content:
              'Recovery initiated successfully. You will receive an email with further instructions momentarily.',
            actions: [
              {
                text: 'Close',
              },
            ],
          }).modal('show');
        } else {
          // if (data?.cft_error) turnstile.reset();
          turnstile.reset();

          $.toast({
            position: 'top attached',
            class: 'error',
            message: 'Account recovery failed',
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        $('.form').removeClass('loading');
      });
  } else {
    $.toast({
      position: 'top attached',
      class: 'error',
      message: 'An email is required for account recovery',
    });
  }
});
